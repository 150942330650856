
let temp = {}

module.exports = {
  async getStakingData() {
    if (temp['getStakingData']) {
      return temp['getStakingData']
    }
    let staking = await window.$fetch({
      headers: {
        Authorization: window.localStorage.getItem('token')
      },
      url: '/user/stakingData'
    }).then(res => res.data)
    temp['getStakingData'] = staking
    return staking
  }
}