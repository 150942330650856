<template>
  <div class="registerContainer">
    <Navbar navTitle="REGISTER"></Navbar>
    <main>
      <h3 style="text-align: center; color: #fff">
        Basic Mining Registration (BSC)
      </h3>
      <div class="formContainer">
        <van-form @submit="onSubmit">
          <van-tabs v-if="!disablePhone" v-model="tabIndex">
            <van-tab :title="t('mobile')">
              <div style="padding-top: 20px">
                <PhoneInput
                  name="phone"
                  v-model="form.phone"
                  @change="(val) => (form.phoneObj = val)"
                ></PhoneInput>
              </div>
            </van-tab>
            <van-tab :title="t('email')">
              <div style="padding-top: 20px">
                <van-field
                  v-model="form.email"
                  center
                  clearable
                  :label="t('email')"
                  :placeholder="t('inputEmail')"
                  name="email"
                  type="email"
                  :rules="[{ required: tabIndex == 1 }]"
                >
                </van-field>
              </div>
            </van-tab>
          </van-tabs>
          <div v-else style="padding-top: 20px">
            <van-field
              v-model="form.email"
              center
              clearable
              :label="t('email')"
              :placeholder="t('inputEmail')"
              name="email"
              type="email"
              :rules="[{ required: true }]"
            >
            </van-field>
          </div>

          <div style="height: 20px"></div>
          <van-field
            v-model="form.imgCode"
            center
            clearable
            :label="t('imgCode')"
            :placeholder="t('inputImgCode')"
            maxlength="30"
            autocomplete="off"
            name="imgCode"
          >
            <template #button>
              <img
                style="width: 100px"
                @click="imgCodeUrl = $BASEURL + 'captcha?' + Date.now()"
                :src="imgCodeUrl"
                alt=""
              />
            </template>
          </van-field>
          <van-field
            v-model="form.verifyCode"
            center
            clearable
            :label="t('VerificationCode')"
            :placeholder="t('inputCode')"
            maxlength="30"
            autocomplete="off"
            name="verifyCode"
            :rules="[{ required: true }]"
          >
            <template #button>
              <van-button
                v-if="codeCount > 0"
                size="small"
                native-type="button"
                type="primary"
                >{{ codeCount }}</van-button
              >
              <van-button
                :loading="codeLoading"
                v-else
                native-type="button"
                @click="getCode"
                size="small"
                type="primary"
                >{{ t("Send") }}</van-button
              >
            </template>
          </van-field>
          <van-field
            v-model="form.password"
            autocomplete="off"
            center
            clearable
            type="password"
            :label="t('Password')"
            :placeholder="t('inputPassword')"
            maxlength="30"
            name="password"
            :rules="[
              {
                pattern: new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}'),
                message: t('inputPassword'),
              },
            ]"
          >
          </van-field>
          <van-field
            v-model="form.passwordr"
            autocomplete="off"
            center
            clearable
            type="password"
            :label="t('PasswordAgain')"
            :placeholder="t('inputPasswordAgain')"
            maxlength="30"
            name="passwordr"
            :rules="[
              {
                validator: (val) => {
                  return val == form.password;
                },
                message: $t('common.两次密码输入不一致'),
              },
            ]"
          >
          </van-field>

          <van-field
            v-model="form.walletAddress"
            center
            clearable
            :label="t('walletAddress')"
            :placeholder="t('inputWalletAddress')"
            type="textarea"
            rows="1"
            autosize
            name="walletAddress"
            :rules="[{ required: true }]"
          >
          </van-field>

          <van-field
            v-model="form.loginCode"
            center
            clearable
            :label="t('LoginCode')"
            :placeholder="t('inputLoginCode')"
            name="LoginCode"
            :rules="[{ required: true }]"
            right-icon="question-o"
            @click-right-icon="showLoginCode"
            @blur="checkLoginCode"
          >
          </van-field>

          <van-field
            v-model="form.sn"
            center
            clearable
            :label="t('SN')"
            :placeholder="t('inputSN')"
            name="sn"
            :rules="[{ required: true }]"
            right-icon="question-o"
            @click-right-icon="showSN"
            @blur="checkLoginCode"
          >
          </van-field>

          <div style="margin: 56px 0 16px">
            <van-button
              :loading="submitLoading"
              round
              block
              type="info"
              native-type="submit"
              >{{ t("Submit") }}</van-button
            >
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <router-link style="font-size: 12px" to="/login">{{
              t("toSignIn")
            }}</router-link>
            <router-link style="font-size: 12px" to="/changepassword">{{
              t("forgetPsw")
            }}</router-link>
          </div>
        </van-form>
      </div>
    </main>
    <RegisterSuccess
      @success="
        () => {
          $router.push('/login?first=true&tab=' + tabIndex);
        }
      "
      ref="RegisterSuccess"
    ></RegisterSuccess>
  </div>
</template>

<script>
import PhoneInput from "@/components/PhoneInput/PhoneInputNo86";
const validateEmail = require("@/tools/validateEmail.js");
import RegisterSuccess from "./RegisterSuccess";
const loginCodeImg = require("@/assets/loginCode.png");
const snImg = require("@/assets/sn.png");

export default {
  components: {
    PhoneInput,
    RegisterSuccess,
  },
  data() {
    return {
      tabIndex: 1,
      codeCount: 0,
      codeLoading: false,
      submitLoading: false,
      disablePhone: true,
      imgCodeUrl: "",
      form: {
        phoneObj: {},
        email: "",
        phone: "",
        verifyCode: "",
        password: "",
        passwordr: "",
        walletAddress: "",
        country: "",
        province: "",
        city: "",
        address: "",
        zip: "",
        inviteCode: "",
        recommendCode: "",
      },
    };
  },
  mounted() {
    this.imgCodeUrl = this.$BASEURL + "captcha?" + Date.now();
    this.getIpLocation();
  },
  methods: {
    checkLoginCode() {
      if (this.form.loginCode) {
        let loginCode = this.form.loginCode.trim().split(" ")[0];
        if (loginCode.match(/Deeper-Wire/)) {
          loginCode = loginCode.split("-")[3];
        }
        this.form.loginCode = loginCode;
      }
      if (this.form.sn) {
        let sn = this.form.sn.trim();
        sn = sn.split("|")[0];
        this.form.sn = sn;
      }
    },
    showLoginCode() {
      window.open(loginCodeImg);
    },
    showSN() {
      window.open(snImg);
    },
    getIpLocation() {
      this.disablePhone = true;
      this.tabIndex = 1;
      // this.$axios({
      //   url: this.$BASEURL + "user/ipLocation",
      // }).then((res) => {
      //   if (res.data.data == "CN") {
      //     this.disablePhone = true;
      //     this.tabIndex = 1;
      //   }
      // });
    },
    t(param) {
      return this.$t(`register.${param}`);
    },
    onSubmit(e) {
      if (this.form.password != this.form.passwordr) {
        return this.$ctToast("两次密码输入不一致");
      }
      if (!/^(0x)?[0-9a-fA-F]{40}$/.test(this.form.walletAddress)) {
        return this.$ctToast("请输入正确的BEP20钱包地址");
      }
      if (this.tabIndex == 0) {
        if (!this.form.phoneObj.formattedNumber) {
          return this.$ctToast("请输入手机号码");
        }
      }
      if (this.tabIndex == 1) {
        if (!this.form.email || !validateEmail(this.form.email)) {
          return this.$ctToast("请输入正确的邮箱地址");
        }
      }
      if (!this.form.verifyCode) {
        return this.$ctToast("请输入验证码");
      }
      if (!this.form.password) {
        return this.$ctToast("请输入密码");
      }
      let loginCode = this.form.loginCode && this.form.loginCode.split("|")[1];
      if (!loginCode) {
        return this.$ctToast("请检查设备登录页面的编码是否正确");
      }
      this.submitLoading = true;
      this.$fetch({
        url: "/user/register",
        method: "POST",
        data: {
          ...this.form,
          sn: this.form.sn.trim() + "|" + loginCode,
          username:
            this.tabIndex == 0
              ? this.form.phoneObj.formattedNumber
              : this.form.email,
          password: encryptWithPublicKey(this.form.password),
        },
      })
        .then((res) => {
          this.$refs.RegisterSuccess.open();
          // this.$router.push("/login?first=true&tab="+this.tabIndex);
        })
        .catch((e) => {
          console.log(e);
          if (e == "erc20SN") {
            alert(window.$$i18n.t("resCommon.erc20SN"));
            window.location.href = "https://device.deeper.network/";
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    async startCodeCount() {
      this.codeCount = 60;
      while (this.codeCount > 0) {
        await this.$sleep(1000);
        this.codeCount--;
      }
    },
    getCode() {
      if (!this.form.imgCode) {
        return this.$ctToast("请输入图片验证码");
      }
      if (this.tabIndex == 0) {
        if (!this.form.phoneObj.formattedNumber) {
          return this.$ctToast("请输入正确的手机号码");
        }
      }
      if (this.tabIndex == 1) {
        if (!validateEmail(this.form.email)) {
          return this.$ctToast("请输入正确的邮箱地址");
        }
      }

      this.codeLoading = true;
      this.$fetch({
        url:
          this.tabIndex == 1 ? "/user/generateEmailCode" : "/user/generateCode",
        method: "POST",
        data: {
          imgCode: this.form.imgCode,
          phone: this.form.phoneObj.formattedNumber,
          email: this.form.email,
        },
      })
        .then((res) => {
          this.startCodeCount();
        })
        .finally(() => {
          this.codeLoading = false;
        });
    },
  },
};
</script>
<style>
html,
body,
#app {
  height: 100%;
}
</style>
<style lang="less" scoped>
.registerContainer {
  min-height: 100%;
  background: #815611;
  padding-bottom: 20px;
  main {
    padding: 0 10px;
  }
}
.formContainer {
  margin: 30px auto 0;
  width: 100%;
  max-width: 600px;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
}
@media screen and (max-width: 500px) {
  .formContainer {
    padding: 30px 10px;
  }
}
</style>
