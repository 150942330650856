<template>
  <div class="step2Container">
    <DPRSlider :min="getCredit()" v-model="chooseScore"></DPRSlider>
    <div>
      <van-cell-group>
        <van-cell center :title="$t('user.needDPRtoUpdate')">
          <div
            style="
              color: #666;
              font-weight: bold;
              font-size: 25px;
              text-align: center;
            "
            slot="default"
            :key="haveStakingAmount"
          >
            <img
              style="margin-right: 5px"
              :src="require('@/assets/dprCoin_mini.png')"
              alt=""
            />
            {{ getDPRAmount() }}
          </div>
        </van-cell>

        <van-cell center :title="$t('user.dailyReward')">
          <div class="cellBox" slot="default">
            <a href="https://dpr.deeper.network/calculator" target="_blank"
              ><van-button>{{ $t("user.View") }}</van-button></a
            >
          </div>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="btnBox">
      <van-button @click="$emit('prev')">{{ $t("user.previous") }}</van-button>
      <van-button @click="$emit('next')" style="width: 30%" type="primary">{{
        $t("user.stake")
      }}</van-button>
    </div>
    <div
      v-if="!haveOpenMiningBefore && haveStakingAmount < 1000"
      class="openMinerBox"
    >
      <div style="width: 80%; margin-right: 10px; font-size: 14px">
        {{ $t("user.canStake1000") }}
      </div>
      <div>
        <van-button @click="onlyUpdateMinder" type="warning" plain>
          {{ $t("user.canStake1000Btn") }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import DPRSlider from "@/components/DPRSlider/DPRSlider";
const { getStakingData } = require("./service");
export default {
  props: {
    comps: null,
  },
  components: {
    DPRSlider,
  },
  data() {
    return {
      chooseScore: 100,
      scoreObj: {},
      haveStakingAmount: "",
      haveStakingCredit: "",
      aprBefore: "",
      dailyBefore: "",
      haveStaking: {},
      deviceSNType: "",
      haveOpenMiningBefore: "",
    };
  },
  methods: {
    onlyUpdateMinder() {
      this.chooseScore = 0;
      this.$emit("next");
    },
    getDaily() {
      const { scoreObj, chooseScore } = this;
      console.log(chooseScore);

      if (scoreObj[`$${chooseScore}`]) {
        return (
          (scoreObj[`$${chooseScore}`].apr * scoreObj[`$${chooseScore}`].dpr) /
          365
        ).toFixed(2);
      }
    },
    getAPR() {
      const { scoreObj, chooseScore } = this;
      if (scoreObj[`$${chooseScore}`]) {
        return (scoreObj[`$${chooseScore}`].apr * 100).toFixed(3) / 1 + "%";
      }
    },
    async init() {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.getScore();
      await this.getHaveStakingAmount();
      this.initChooseScore();
      this.$toast.clear();
    },
    async getHaveStakingAmount() {
      const haveStaking = await getStakingData();
      console.log(haveStaking);
      if (haveStaking && haveStaking.creditStaking / 1) {
        this.haveStakingAmount = haveStaking.creditStaking;
        this.haveStakingCredit = haveStaking.creditLevelScore;
        if (this.scoreObj[`$${haveStaking.credit}`]) {
          this.aprBefore =
            (this.scoreObj[`$${haveStaking.credit}`].apr * 100).toFixed(4) / 1;
          this.dailyBefore =
            (
              (this.aprBefore * this.scoreObj[`$${haveStaking.credit}`].dpr) /
              100 /
              365
            ).toFixed(2) / 1;
        }
      } else if (this.deviceSNType == "DPR") {
        this.haveStakingAmount = 0;
        this.haveStakingCredit = 100;
        if (this.scoreObj[`$${this.haveStakingCredit}`]) {
          this.aprBefore =
            (this.scoreObj[`$${this.haveStakingCredit}`].apr * 100).toFixed(4) /
            1;
          this.dailyBefore =
            (
              (this.aprBefore *
                this.scoreObj[`$${this.haveStakingCredit}`].dpr) /
              100 /
              365
            ).toFixed(2) / 1;
        }
      } else {
        this.haveStakingAmount = 0;
        this.haveStakingCredit = 0;
        this.aprBefore = 0;
        this.dailyBefore = 0;
      }
    },
    getScore() {
      this.scoreObj = this.comps.Step1.scoreMap;
      this.deviceSNType = this.comps.Step1.deviceSNType;
      this.haveOpenMiningBefore = this.comps.Step1.haveOpenMiningBefore;
    },
    getDPRAmount() {
      console.log(this.scoreObj);
      let result =
        this.scoreObj[`$${this.chooseScore}`] &&
        this.scoreObj[`$${this.chooseScore}`].need;
      if (result) {
        return (result - this.haveStakingAmount).toFixed(3) / 1;
      }
    },
    initChooseScore() {
      this.chooseScore = this.getCredit();
    },
    getCredit() {
      let result = 0;
      if (this.haveStakingAmount) {
        let scoreList = Object.values(this.scoreObj);
        for (let i = 0; i < scoreList.length; i++) {
          if (this.haveStakingAmount.toFixed(4) / 1 >= scoreList[i].need) {
            result = scoreList[i].credit;
          }
        }
        console.log(result);
      } else if (this.deviceSNType == "DPR") {
        return 200;
      }

      if (result == 800) {
        return 800;
      }
      return result + 100;
    },
  },
};
</script>
<style lang="less" scoped>
.step2Container {
  padding: 10px 30px;
  .btnBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  .cellBox {
    color: #666;
    font-weight: bold;
    font-size: 25px;
    text-align: right;
    .deleteBox {
      display: inline-block;
      width: 30%;
      font-size: 18px;
      text-decoration: line-through;
      text-decoration-thickness: 3px;
    }
  }
  .openMinerBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 30px;
  }
}
</style>