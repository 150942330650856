<template>
  <div>
    <van-button type="info" size="small" round plain @click="openModal">{{lang[$$i18n.locale].connect}}<span v-if="account">({{account.slice(0,4) + '**' +account.slice(-4)}})</span> </van-button>
    <van-popup get-container="#app" v-model="show">
      <div class="container">
        <div @click="changeMetamask" class="walletBox">
          <img :src="logoImg.mm" alt="">
        </div>
        <div class="walletBox" @click="changeWalletConnect">
          <img style="width: 30%" :src="logoImg.wc" alt=""> <div style="font-size: 16px; font-weight: bold; color: #64748B;margin-left: 10px;">WalletConnect</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import mmlogo from './imgs/mm-logo.svg'
import wclogo from './imgs/walletconnect-logo.svg'
import ethService from '@/services/eth.js'

export default {
  props: {
    account: String
  },
  data() {
    return {
      show: false,
      logoImg: {
        mm: mmlogo,
        wc: wclogo
      },
      lang: {
        en: {
          connect: 'Connect Wallet'
        },
        cn: {
          connect: '连接钱包'
        },
        ko: {
          connect: '지갑 연결'
        }
      }
    }
  },
  methods: {
    async changeWalletConnect() {
      await ethService.walletConnect().catch(e => {
        console.log(e)
      })
      this.$emit('change')
      this.show = false
    },
    async changeMetamask() {
      await ethService.metamask()
      this.$emit('change')
      this.show = false
    },
    openModal() {
      this.show = true
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  max-width: 250px;
}
.walletBox {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  padding: 30px;
  border-bottom: 1px solid #ddd;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
}
</style>