<template>
<div>
  <van-popup :close-on-click-overlay="false" v-model="show" style="width: 100%;max-width: 600px" round>
    <div class="createMain">
      <div>
        {{t('clickInOrder')}}
      </div>
      <div class="btnList">
        <van-button type="info" @click="addCheckStr(item, index)" v-show="item.show" plain v-for="(item, index) in shuffleList" :key="item.word + index">{{item.word}}</van-button>
      </div>
      <div class="wordBox">
        <div style="min-height: 50px">
          {{checkStr}}
        </div>
         <div style="text-align: right">
          <van-button size="mini" @click="resetCheckStr" plain>{{t('Reset')}}</van-button>
        </div>
      </div>
      <div>
        <div class="btnBox">
          <van-button type="warning" @click="show1=true;show=false" plain>{{t('Back')}}</van-button>
          <van-button type="primary" @click="submitWallet">{{t('Confirm')}}</van-button>
        </div>
      </div>
    </div>
  </van-popup>
  <van-popup v-model="show1" :close-on-click-overlay="false" style="width: 100%; max-width: 600px" round>
    <div style="padding: 20px">
      <div class="mnemonicBox">
        <span v-for="(item, index) in mnemonicList" :key="item + index">{{item}}</span>
      </div>
      <div>
        <van-button style="margin-bottom: 20px" block type="warning" @click="download" plain>{{t('DownloadKey')}}</van-button>
        <van-button block type="primary" @click="show1=false;show = true">{{t('confirmBtnText')}}</van-button>
      </div>
    </div>
  </van-popup>
</div>
  
</template>

<script>
import create from './create'
function shuffle(arr) {
  var i = arr.length, t, j;
  while (i) {
    j = Math.floor(Math.random() * i--);
    t = arr[i];
    arr[i] = arr[j];
    arr[j] = t;
  }
  return arr
}

export default {
  data() {
    return {
      show: false,
      show1: false,
      mnemonicList: [],
      shuffleList: [],
      mnemonic: '',
      privateKey: '',
      publicKey: '',
      checkStr: '',
      type: ''
    };
  },
  methods: {
    download() {
      const filename = 'deeperchain_key.txt';
      const text = this.privateKey;
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
    
      element.style.display = 'none';
      document.body.appendChild(element);
    
      element.click();
    
      document.body.removeChild(element);
    },
    t(param) {
      return this.$t(`createWallet.${param}`)
    },
    resetCheckStr() {
      this.shuffleList = this.shuffleList.map(it => {
        it.show = true;
        return it
      })
      this.checkStr = ''
    },
    addCheckStr(item, index) {
      this.checkStr += ' ' + item.word;
      this.shuffleList[index].show = false
    },
    create(type) {
      this.checkStr = '';
      this.mnemonicList = [];
      this.shuffleList = [];
      this.mnemonic = '';
      this.privateKey = '';
      this.publicKey = ''
      this.createWallet()
      this.type = type || ''
    },
    submitWallet() {
      if (this.checkStr.trim() !== this.mnemonic.trim()) {
        return this.$notify({
          type: 'danger',
          message: this.t('confirmError')
        })
      }
      this.$Toast.loading({
        forbidClick: true,
        duration: 0
      });
      this.$fetch({
         headers: {
          Authorization: `${window.localStorage.getItem("token")}`,
        },
        // method: this.type == 'EDIT' ? 'PUT' :"POST",
        method: 'POST',
        url: "/user/deeperwallet",
        data: {
          deeperWallet: this.publicKey
        }
      }).then(res => {
        this.$notify({
          type: 'success',
          message: 'Success'
        })
        this.show = false;
        this.$emit('success')
      }).finally(() => {
        this.$Toast.clear()
      })
    },
    createWallet() {
      let {mnemonic, publicKey, privateKey} = create();
      this.$Dialog({
        message: this.t('recordTips')
      }).then(() => {
        this.show1 = true
        this.mnemonic = mnemonic
        this.mnemonicList = mnemonic.split(' ')
        this.shuffleList = shuffle(mnemonic.split(' ').map(it => {
          return {
            word: it,
            show: true
          }
        }))
        this.privateKey = privateKey
        this.publicKey = publicKey
      });
    },
  },
};
</script>

<style>
.van-dialog__message {
  overflow-x: hidden
}
</style>

<style lang="less" scoped>
.createMain {
  min-height: 50vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}
.btnList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0;
  button {
    margin: 5px;
    // font-size: 18px;
  }
}
.wordBox {
  background-color: #eee;
  padding: 10px;
  line-height: 30px;
  font-size: 18px;
}
.btnBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  button {
    width: 40%
  }
}
.mnemonicBox {
  margin-bottom: 30px;
  span {
    display: inline-block;
    padding: 10px;
    margin: 5px;
    border: 1px solid #bbb
  }
}
</style>