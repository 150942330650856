<template>
  <PhoneInput
    :value="value"
    @update="onChange"
    :default-country-code="$$i18n.locale == 'cn' ? 'CN' : 'US'"
    v-bind:no-example="true"
    v-bind:no-validator-state="true"
    :name="name"
    :translations="{
        countrySelectorLabel: $t('vuePhoneComponent.Callingcode'),
        phoneNumberLabel: $t('vuePhoneComponent.EnterPhoneNumber'),
    }"
  ></PhoneInput>
</template>

<script>
import PhoneInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {PHONE_SUPPORTED_COUNTRIES} from './config'

export default {
  props: {
    value: {
      type: String
    },
    name: {
      type: String
    }
  },
  // computed: {
  //   lang() {
  //     return Vue.prototype.$$i18n.locale
  //   }
  // },
  components: {
    PhoneInput
  },
  data() {
    return {
      supportedCountries: PHONE_SUPPORTED_COUNTRIES
    }
  },
  methods: {
    onChange(e) {
      console.log(e)
      this.$emit('input', e.phoneNumber)
      this.$emit('change', e)
    }
  }
}
</script>

<style>

</style>
