export const PHONE_SUPPORTED_COUNTRIES = [
    'CN', // China
    'US', // United States
    'AR', // Argentina
    'AU', // Australia
    'AT', // Austria
    'BE', // Belgium
    'BR', // Brazil
    'CA', // Canada
    'CL', // Chile
    'CO', // Colombia
    'DK', // Denmark
    'FI', // Finland
    'FR', // France
    'DE', // Germany
    'GR', // Greece
    'HK', // Hong Kong
    'HU', // Hungary
    'IS', // Iceland
    'IN', // India
    'IE', // Ireland
    'IT', // Italy
    'JP', // Japan
    'LU', // Luxembourg
    'MO', // Macao
    'MY', // Malaysia
    'MX', // Mexico
    'NL', // Netherlands
    'NZ', // New Zealand
    'NO', // Norway
    'PL', // Poland
    'PT', // Portugal
    'RU', // Russia
    'SA', // Saudi Arabia
    'SG', // Singapore
    'ZA', // South Africa
    'KR', // South Korea
    'ES', // Spain
    'SE', // Sweden
    'CH', // Switzerland
    'TW', // Taiwan
    'GB', // United Kingdom
    'NG', // Nigeria
    'UA', // Ukraine,
    'HR', // Croatia
    'CZ', // Czech Republic
];
