<template>
  <div class="sliderContainer">
    <h3 class="titleBox">{{lang[$$i18n.locale].select}}</h3>
    <div class="dprCoinBox">
      <img :style="{width: `${20+value/10}%`}" :src="require('@/assets/dprCoin.png')" alt="">
    </div>
    <van-slider
      bar-height="10px"
      active-color="#358BFF"
      :min="min || 100"
      :max="800"
      :value="value"
      :step="100"
      @input="onChange"
    >
    <template #button>
      <van-button
        style="
          height: 25px;
          width: 40px;
          color: #358BFF;
          border-radius: 10px;
          box-shadow: 0 0 10px #aaa
        "
        > ||||| </van-button
      >
    </template>
    </van-slider>
    <div class="markBox">
      <div @click="onChange(item/1)" v-for="item in levelList" :key="item" :class="{numBox: true, active: value == item}">{{item}}</div>
    </div>
  </div>
</template>

<script>
import '@vant/touch-emulator';
export default {
  props: {
    value: null,
    min: null,
    title: String,
    useSlot: null,
    smTitle:String
  },
  data() {
    return {
      lang: {
        en: {
          select: 'Please select credit',
        },
        cn: {
          select: '请选择信用分',
        }
      }
      // levelList: ['1', '2', '3', '4', '5', '6', '7', '8']
    }
  },
  computed: {
    levelList() {
      let list= ['100', '200', '300', '400', '500', '600', '700', '800'];
      if (this.min) {
        return list.slice(this.min/100-1)
      }
      return list
    }
  },
  mounted() {
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="less" scoped>
.sliderContainer {
  position: relative;
  padding: 30px 10px 60px;
  .titleBox {
    border-left: 5px solid #4F9BFF;
    padding-left: 10px;
    margin: 0;
    margin-bottom: 20px;
    font-size: 16px;
    width: 70%;
    word-break: break-word;
    min-height: 50px;
    display: flex;
    align-items: center;
  }
  .smTitleBox {
    color: #4F9BFF;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .dprCoinBox {
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      transition: all 0.3s ease;
    }
  }
  .levelLabel {
    position: absolute;
    right: 0;
    top: 60px;
    padding:2px 10px;
    background: #4F9BFF;
    color: #fff;
    font-size: 12px;
  }
  .markBox {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    margin-left: -5px;
    .numBox {
      position: relative;
      width: 10px;
      height: 20px;
      color: #666;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -20px;
        width: 2px;
        left: 50%;
        transform: translateX(-50%);
        height: 15px;
        background: #ddd;
        z-index: 0;
      }
      &.active {
        color: #1989fa;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
</style>