<template>
  <div class="registerContainer">
    <Navbar navTitle="PASSWORD"></Navbar>
    <main>
      <div class="formContainer">
        <van-form >
          <van-tabs v-model="tabIndex">
            <van-tab :title="t('mobile')">
              <div style="padding-top: 20px;">
                <PhoneInput
                  name="phone"
                  v-model="form.phone"
                  @change="(val) => (form.phoneObj = val)"
                ></PhoneInput>
              </div>
            </van-tab>
            <van-tab :title="t('email')">
              <div style="padding-top: 20px">
                <van-field
                  v-model="form.email"
                  center
                  clearable
                  :label="t('email')"
                  :placeholder="t('inputEmail')"
                  name="email"
                  type="email"
                  :rules="[{ required: tabIndex == 1 }]"
                >
                </van-field>
              </div>
              
            </van-tab>
          </van-tabs>
          <div style="height: 20px"></div>
          <van-field
            v-model="form.imgCode"
            center
            clearable
            :label="t('imgCode')"
            :placeholder="t('inputImgCode')"
            maxlength="30"
            autocomplete="off"
            name="imgCode"
          >
            <template #button>
              <img style="width: 100px" @click="imgCodeUrl = $BASEURL+'captcha?'+Date.now()" :src="imgCodeUrl" alt="">
            </template>
          </van-field>
          <van-field
            v-model="form.verifyCode"
            center
            clearable
            :label="t('VerificationCode')"
            :placeholder="t('inputCode')"
            maxlength="30"
            name="verifyCode"
          >
            <template #button>
              <van-button  v-if="codeCount > 0" size="small" native-type="button" type="primary">{{codeCount}}</van-button>
              <van-button :loading="codeLoading" v-else @click="getCode" native-type="button" size="small" type="primary">{{t('Send')}}</van-button>
            </template>
          </van-field>
          <van-field
            v-model="form.password"
            autocomplete="off"
            center
            clearable
            type="password"
            :label="t('Password')"
            :placeholder="t('inputPassword')"
            maxlength="30"
            name="password"
            :rules="[{ pattern: new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}'), message: t('inputPassword') }]"
          >
          </van-field>
          <van-field
            v-model="form.passwordr"
            autocomplete="off"
            center
            clearable
            type="password"
            :label="t('PasswordAgain')"
            :placeholder="t('inputPasswordAgain')"
            maxlength="30"
            name="passwordr"
            :rules="[{ validator: val => {
              return val == form.password
            }, message: $t('common.两次密码输入不一致') }]"
          >
          </van-field>
          
          <div style="margin: 56px 0 16px;">
            <van-button :loading="submitLoading" round block @click="onSubmit" type="info" native-type="submit">{{t('Submit')}}</van-button>
          </div>
        </van-form>
      </div>
      
    </main>
  </div>
</template>

<script>
import PhoneInput from '@/components/PhoneInput/PhoneInput'
export default {
  components: {
    PhoneInput
  },
  data() {
    return {
      tabIndex: 0,
      codeCount: 0,
      codeLoading: false,
      submitLoading: false,
      imgCodeUrl: '',
      form: {
        imgCode: '',
        phoneObj: {},
        phone: '',
        email: '',
        verifyCode: '',
        password: '',
        passwordr: ''
      }
    }
  },
  mounted() {
    this.imgCodeUrl = this.$BASEURL+'captcha?'+Date.now()
  },
  methods: {
    t(param) {
      return this.$t('changePassword.'+param)
    },
    onSubmit(e) {
      if (this.form.passwordr != this.form.password) {
        return this.$ctToast("两次密码输入不一致")
      }
      if (this.tabIndex == 0 && !this.form.phoneObj.formattedNumber) {
        return this.$ctToast("请输入手机号码")
      }
      if (this.tabIndex == 1 && !this.form.email) {
        return this.$ctToast("请输入正确的邮箱地址")
      }
      if (!this.form.verifyCode) {
        return this.$ctToast("请输入验证码")
      }
      if (!this.form.password) {
        return this.$ctToast("请输入密码")
      }
      this.submitLoading = true;
      this.$fetch({
        url: '/user/changePassword',
        method: 'POST',
        data: {
          username: this.tabIndex == 0 ? this.form.phoneObj.formattedNumber : this.form.email,
          verifyCode: this.form.verifyCode,
          password: encryptWithPublicKey(this.form.password)
        }
      }).then(res => {
        this.$toast.success()
        window.localStorage.clear()
        this.$router.push('/login')
      }).finally(() => {
        this.submitLoading = false
      })
    },
    async startCodeCount() {
      this.codeCount = 60;
      while (this.codeCount > 0) {
        await this.$sleep(1000)
        this.codeCount--
      }
    },
    getCode() {
      if (!this.form.imgCode) {
        return this.$ctToast("请输入图片验证码");
      }
      if (this.tabIndex == 0 && !this.form.phoneObj.formattedNumber) {
        return this.$ctToast("请输入手机号码")
      }
      if (this.tabIndex == 1 && !this.form.email) {
        return this.$ctToast("请输入正确的邮箱地址")
      }
      this.codeLoading = true
      this.$fetch({
        url: this.tabIndex == 0 ? '/user/generateCode' : '/user/generateEmailCode',
        method: 'POST',
        data: {
          imgCode: this.form.imgCode,
          phone: this.form.phoneObj.formattedNumber,
          email: this.form.email
        }
      }).then(res => {
        this.startCodeCount()
      }).finally(() => {
        this.codeLoading = false
      })
    }
  }
}
</script>
<style>
html, body, #app {
  height: 100%
}
</style>
<style lang="less" scoped>

.registerContainer {
  min-height: 100%;
  background: #f5f5f5;
}
.formContainer {
  margin: 30px auto 0;
  width: 100%;
  max-width: 600px;
  padding: 30px;
  background: #fff;
}
@media screen and (max-width: 500px) {
  .formContainer {
    padding: 30px 10px;
  }
}
</style>
