<template>
  <footer class="footer">
    <div style="display: flex;justify-content: center;align-items: center">
      <a href="https://t.me/DeeperNetwork" target="_blank">
        <img style="width:30px" :src="require('@/assets/telegram.png')" alt=""> 
      </a>
      <span style="color: #eee;font-size: 12px;margin-left: 10px">{{lang[$$i18n.locale].word}}</span>
    </div>
    <div style="margin-top: 10px;">
      <a target="_blank" href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xa0a2ee912caf7921eaabc866c6ef6fec8f7e90a4">
        <van-button size="small">{{lang[$$i18n.locale].cakeBuy}}</van-button>
      </a>
      
    </div>
    <div style="margin-top: 10px;">
      <a target="_blank" href="https://trade.kucoin.com/trade/DPR-USDT">
        <van-button size="small">{{lang[$$i18n.locale].buy}}</van-button>
      </a>
    </div>
    
  </footer>
</template>

<script>
export default {
  data() {
    return {
      lang: {
        en: {
          word: 'Join us and don’t miss a message',
          buy: 'Click to buy DPR, and then withdraw coins to BSC wallet.',
          cakeBuy: 'Click to buy DPR at PancakeSwap'
        },
        cn: {
          word: '加入我们，时刻关注信息',
          buy: '点击购买DPR, 然后提币到BSC钱包。',
          cakeBuy: '点击在PancakeSwap 购买DPR'
        },
        ko: {
          word: '우리와 함께, 항상 정보에주의를 기울이십시오',
          buy: 'Uniswap에서 DPR 구매'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  text-align: center;
  padding: 30px 0 30px
}
</style>